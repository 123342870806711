import React from 'react'
import homeG from './assets/homeG.gif'
import { TypeAnimation } from 'react-type-animation';

const Home = () => {
    return (
        <div className='w-full flex h-auto lg:h-screen items-start justify-between p-6'>
            <div className=' items-start justify-center flex-col hidden lg:flex px-4 lg:p-12 h-full '>
               
                <p className='text-9xl  font-bold block  whitespace-normal'>
                    WEL
                </p>
                <p className='text-9xl  font-bold block  whitespace-normal'>
                    CO
                </p>
                <p className='text-9xl  font-bold block  whitespace-normal'>
                    ME
                </p>
            </div>
            <img className='w-full' src={homeG}>
            </img>

        </div>
    )
}

export default Home