import Home from "./Home";
import Navbar from "./Navbar";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import yuzuk1 from './assets/yuzuk1.webp';
import yuzuk2 from './assets/yuzuk2.webp';
import yuzuk3 from './assets/yuzuk4.webp';
import ilgi from './assets/ilgi.webp';
import a from './assets/a.webp';
import b from './assets/b.webp';
import c from './assets/c.webp';
import d from './assets/d.webp';
import { IoMail } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa";

import 'swiper/css';
import { FaPhone } from "react-icons/fa";



function App() {



  return (
    <div className="w-full flex-col min-h-screen flex items-start justify-start bg-slate-200">
      <Navbar></Navbar>
      {/* <Home></Home> */}
      <div className="w-full min-h-screen h-auto flex lg:flex-row flex-col gap-3 items-start justify-center p-6">
        <div className="lg:w-1/3 w-full h-full flex-col items-start justify-center gap-6">
          <div className={`w-full h-48 box bg-center bg-cover group transition-all duration-300 bg-no-repeat flex gap-2 flex-col items-center justify-center rounded-md border border-black`}>
            <p className="group-hover:text-white text-3xl italic">
              Görsel serüven, objektifinizle başlar.
            </p>
            <p className="group-hover:text-white text-md text-center">
              Objektif, benim için bir tutku.<br></br>
              Bu tutkuyu, sizinle paylaşmak istiyorum.
            </p>
          </div>

          <img src={yuzuk1} className=" flex-1 h-[50rem] bg-cover  mt-2 w-full " />
          <div className="w-full hidden h-52 mt-6 box bg-center bg-cover group transition-all duration-300 bg-no-repeat lg:flex gap-2 flex-col items-center justify-center rounded-md border border-black">
            <p className="group-hover:text-white text-3xl italic">
              Her an bir sonsuz kare
            </p>
           
          </div>




        </div>
        <div className="lg:w-2/3 w-full flex items-start min-h-screen justify-end flex-col h-auto">
          <img src={yuzuk2} className=" flex-1 lg:h-full h-[50rem] bg-cover  mt-2 w-full " />
          <img src={yuzuk3} className=" flex-1 lg:h-full h-[50rem] bg-cover  mt-2 w-full " />



        </div>
      </div>
      <div className="w-full min-h-screen">
        <iframe className="w-full min-h-screen p-6" src="https://www.youtube.com/embed/86deTwjQFSo" ></iframe>
      </div>
      <div className="w-full grid grid-cols-1 lg:grid-cols-3">
        <iframe className="w-full min-h-screen p-6" src="https://www.youtube.com/embed/vaUBbgL1zk4" title="Tarihteki ilk telefon konuşması" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <iframe className="w-full min-h-screen p-6" src="https://www.youtube.com/embed/9kBpIupDYxA" title="Tarihteki ilk telefon konuşması" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <iframe className="w-full min-h-screen p-6" src="https://www.youtube.com/embed/ioi0y34-13U" title="Tarihteki ilk telefon konuşması" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <div className="w-full min-h-screen flex flex-col lg:flex-row items-center justify-center ">
      
        <iframe className="lg:w-2/3 w-full min-h-screen p-6" src="https://www.youtube.com/embed/f1WblN2Xwgs" title="Tarihteki ilk telefon konuşması" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <iframe className="lg:w-1/3 w-full min-h-screen p-6" src="https://www.youtube.com/embed/uhOshW3ag9k" title="Tarihteki ilk telefon konuşması" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <div className="w-full min-h-screen grid place-items-center grid-cols-1 lg:grid-cols-2">

        <iframe className="w-full h-96 p-6" src="https://www.youtube.com/embed/c5A_C2CjJTE?controls=0&showinfo=0" title="Tarihteki ilk telefon konuşması" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <iframe className="w-full h-96 p-6" src="https://www.youtube.com/embed/2PxXP9Cx7II?controls=0&showinfo=1" title="Tarihteki ilk telefon konuşması" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <iframe className="w-full h-96 p-6" src="https://www.youtube.com/embed/nKNEyCTn43Y?controls=0&showinfo=1" title="Tarihteki ilk telefon konuşması" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <iframe className="w-full h-96 p-6" src="https://www.youtube.com/embed/Rx6lnlpguqE?controls=0&showinfo=1" title="Tarihteki ilk telefon konuşması" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <div className="w-full h-auto flex items-center justify-center">
      
      <iframe className="w-full h-screen p-6" src="https://www.youtube.com/embed/8tqlJEwXztI" title="Tarihteki ilk telefon konuşması" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <div className="w-full min-h-screen grid grid-cols-1 lg:grid-cols-4 place-items-center">
        <LazyLoadImage
          alt={'alt of image'}
          effect="blur"
          src={a}
          className="w-full min-h-screen p-6 object-cover bg-no-repeat bg-center"
        />
        <LazyLoadImage
          alt={'alt of image'}
          effect="blur"
          src={b}
          className="w-full min-h-screen p-6 object-cover bg-no-repeat bg-center"
        />
        <LazyLoadImage
          alt={'alt of image'}
          effect="blur"
          src={c}
          className="w-full min-h-screen p-6 object-cover bg-no-repeat bg-center"
        />
        <LazyLoadImage
          alt={'alt of image'}
          effect="blur"
          src={d}
          className="w-full min-h-screen p-6 object-cover bg-no-repeat bg-center"
        />

      </div>
      <div className="w-full h-auto flex items-center justify-center">
      <iframe className="w-full h-screen p-6" src="https://www.youtube.com/embed/M-VkgAKRm84" title="Tarihteki ilk telefon konuşması" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <div className="w-full h-auto flex items-center justify-center">
      <iframe className="w-full h-screen p-6" src="https://www.youtube.com/embed/c2z4nWCbdjE" title="Tarihteki ilk telefon konuşması" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <div className="w-full grid grid-cols-1 lg:grid-cols-3">
        <iframe className="w-full min-h-screen p-6" src="https://www.youtube.com/embed/RVQtsKJD1-Q" title="Tarihteki ilk telefon konuşması" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <iframe className="w-full min-h-screen p-6" src="https://www.youtube.com/embed/oDDMWl3MBOg" title="Tarihteki ilk telefon konuşması" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <iframe className="w-full min-h-screen p-6" src="https://www.youtube.com/embed/u_AljfbvikU" title="Tarihteki ilk telefon konuşması" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <footer className="w-full h-40 text-white py-12 lg:py-0 bg-slate-950 flex flex-col lg:flex-row items-center justify-center lg:justify-between">
        <p className='text-5xl tracking-[10px] font-light uppercase whitespace-nowrap p-2 px-6 '>
          <strong>#</strong>Hakan Bedir
        </p>
        <div className="flex w-full flex-col items-center justify-center lg:items-end lg:justify-end px-12">
          <h3 className="uppercase text-3xl border-b border-white ">contact me</h3>
          <div className="w-full flex  items-start justify-center lg:justify-end p-3 gap-4 ">
            <a href="tel:05377403138" className="group">
              <FaPhone className="text-2xl group-hover:text-orange-600" />
            </a>
            <a href="mailto:hakanbedir1611@gmail.com" className="group">
              <IoMail className="text-3xl group-hover:text-orange-600" />
            </a>


            <a href="https://www.instagram.com/hakanwz" className="group">
              <FaInstagram className="text-3xl group-hover:text-orange-600" />
            </a>


          </div>
        </div>
      </footer>

    </div>
  );
}

export default App;
