import React from 'react'

const Navbar = () => {
  return (
    <div className='w-full h-12 flex items-center justify-start p-8'>
        <h1 className='text-4xl font-light uppercase whitespace-nowrap p-2 px-6 '>
            Hakan Bedir
        </h1>
        <span className='w-full h-px bg-black'/>
        <h2 className='text-4xl font-light uppercase whitespace-nowrap p-2 px-6 '>
        Portfolio
        </h2>
        <span className='w-0 lg:w-6 h-px  bg-black'/>

        

        

    </div>
  )
}

export default Navbar